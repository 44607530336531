<template>
  <div class="flex flex-col items-center">
    <div class="banner">
      <div class="logo">
        <img
          src="../assets/img/logo.png"
          srcset="../assets/img/logo@2x.png 2x"
          alt="logo"
        />
      </div>
      <div class="content relative">
        <div class="content-box flex flex-col">
          <h1>Download JustChat App</h1>
          <div class="space-y-10 text-box">
            <div class="text flex items-start">
              <img src="../assets/img/icon.svg" alt="icon" class="icon" />
              <p>Minimalist interface focused on chat experience</p>
            </div>
            <div class="text flex items-start">
              <img src="../assets/img/icon.svg" alt="icon" class="icon" />
              <p>
                State-of-the-art end-to-end encryption (powered by the open
                source Signal Protocol) keeps your conversations secure
              </p>
            </div>
          </div>
          <!--
          <div
            class="button-box space-x-8 flex justify-center sm:justify-start"
          >
            <a
              class="cursor-pointer"
              href="https://apps.apple.com/cn/app/justchat/id1577458560"
            >
              <img
                src="../assets/img/mac.png"
                srcset="../assets/img/mac@2x.png 2x"
                alt="mac"
                style="width: 180px"
              />
            </a>
            <a
              class="cursor-pointer"
              href="https://ijust.chat/JustChat-1.0.8.apk"
              download="JustChat-1.0.8.apk"
            >
              <img
                src="../assets/img/andriod.png"
                srcset="../assets/img/andriod@2x.png 2x"
                alt="andriod"
                style="width: 180px"
              />
            </a>
          </div>
          -->
        </div>
        <div class="bg sm:absolute">
          <img
            src="../assets/img/bg.png"
            srcset="../assets/img/bg@2x.png 2x"
            alt="background"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.banner {
  padding: 30px;
}

.logo > img {
  width: 180px;
}

.content-box {
  margin-top: 10.67vw;
}

.content-box > h1 {
  font-family: Futura-Bold, Futura;
  max-width: 315px;
  font-size: 32px;
  font-weight: bold;
  color: #1e1e22;
}

.text-box {
  margin-top: 8.53vw;
}

.text > p {
  /* max-width: 485px; */
  font-size: 16px;
  font-family: DINPro-Medium, DINPro;
  color: #858585;
  line-height: 24px;
}
.icon {
  margin-right: 12px;
}

.button-box {
  margin-top: 45px;
}

.bg > img {
  margin: 13.33vw 0;
  margin-left: -5vw;
  transform: scale(1.5);
}

@screen sm {
  .banner {
    padding: 0;
    margin-bottom: 252px;
    padding-top: 57px;
    width: 974px;
    /* padding-left: 16.25%; */
  }

  .logo > img {
    width: 216px;
  }

  .content-box {
    margin-top: 124px;
  }
  .content-box > h1 {
    max-width: 350px;
    font-size: 49px;
  }

  .text-box {
    margin-top: 45px;
  }

  .text > p {
    max-width: 485px;
    font-size: 19px;
  }

  .icon {
    margin-right: 12px;
  }

  .button-box {
    margin-top: 45px;
  }

  .bg > img {
    width: 766px;
    margin: 0;
    transform: scale(1);
  }

  .bg {
    right: -188px;
    top: -201px;
  }
}
</style>
