<template>
  <div class="container-c flex flex-col items-center">
    <div class="content flex flex-col items-center sm:items-start sm:flex-row">
      <img
        src="../assets/img/center.png"
        srcset="../assets/img/center@2x.png 2x"
        alt="center"
      />
      <div class="text">
        <h2>Instant Messaging, Stable and safe</h2>
        <p>
          Unlimited member groups, private conversations, there is always a
          community for you
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.container-c {
  background: #f3f9ff;
  padding: 20vw 30px;
}

.content > img {
  max-width: 70%;
}

.text {
  margin-top: 10.67vw;
}

.text > h2 {
  font-size: 32px;
  font-weight: bold;
  color: #1e1e22;
  font-family: Futura-Bold, Futura;
  width: 95%;
  margin: auto;
}

.text > p {
  font-size: 19px;
  color: #858585;
  font-family: DINPro-Medium, DINPro;
  width: 95%;
  margin: auto;
  margin-top: 30px;
}

@screen sm {
  .container-c {
    background: #f3f9ff;
    padding-top: 142px;
    padding-bottom: 92px;
  }

  .content > img {
    width: 433px;
  }

  .content > .text {
    margin-left: 112px;
  }

  .text {
    margin-top: 0;
  }

  .text > h2 {
    width: 430px;
    font-size: 39px;
  }

  .text > p {
    margin-top: 30px;
    width: 396px;
    margin-left: 0;
  }
}
</style>