<template>
  <div class="flex flex-col items-center footer">
    <h2 class="text-center">Why JustChat?</h2>
    <div class="flex flex-col space-y-8 f-box sm:space-y-0 sm:flex-row sm:space-x-8">
      <div class="flex flex-col items-center box">
        <img src="../assets/img/simple.png" srcset="../assets/img/simple@2x.png 2x" alt />
        <p class="center-t">Simple</p>
        <p class="text-center top-t">JustChat is very easy to use and does not require any guidance</p>
      </div>
      <div class="flex flex-col items-center box">
        <img src="../assets/img/private.png" srcset="../assets/img/private@2x.png 2x" alt />
        <p class="center-t">Private</p>
        <p class="text-center top-t">
          JustChat messages are protected by the state-of-the-art end-to-end
          encryption
        </p>
      </div>
      <div class="flex flex-col items-center box">
        <img src="../assets/img/fast.png" srcset="../assets/img/fast@2x.png 2x" alt />
        <p class="center-t">Fast</p>
        <p class="text-center top-t">JustChat deliver messages faster than any other IM</p>
      </div>
    </div>
    <div class="flex items-center list">
      <div class="item">
        <a :href="`${publicPath}privacy-en.html`" target="_black">Privacy</a>
      </div>
      <div class="cut"></div>
      <div class="item">
        <a :href="`${publicPath}service-en.html`" target="_black">Terms</a>
      </div>
      <div class="cut"></div>
      <div class="item">
        <a :href="`${publicPath}contact-en.html`" target="_black">Contact</a>
      </div>
    </div>
    <span class="text-center finish">Shenzhen Big Eye Fish Technology Co.</span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>

<style scoped>
.footer {
  padding-top: 90px;
  padding-bottom: 63px;
}

.footer > h2 {
  font-size: 32px;
  font-family: Futura-Bold, Futura;
  font-weight: bold;
  color: #1e1e22;
  line-height: 59px;
}

.f-box {
  margin-top: 90px;
}

.box {
  width: 302px;
  box-shadow: 0px 21px 25px 0px rgba(73, 97, 114, 0.06);
  padding: 0 56px;
  padding-top: 35px;
  padding-bottom: 72px;
}

.finish {
  margin-top: 10px;
  font-size: 19px;
  font-family: DINPro-Medium, DINPro;
  color: #cecece;
  line-height: 24px;
}

.center-t {
  font-size: 27px;
  font-family: Futura-Medium, Futura;
  color: #1e1e22;
  line-height: 35px;
  margin-top: 15px;
}
.top-t {
  font-size: 19px;
  font-family: DINPro-Regular, DINPro;
  font-weight: 400;
  color: #858585;
  line-height: 24px;
  margin-top: 30px;
}

.list {
  margin-top: 190px;
}

.item {
  padding: 0 14px;
}

.cut {
  width: 1px;
  background: #999;
  height: 12px;
}

@screen sm {
  .footer {
    padding-top: 90px;
    padding-bottom: 63px;
  }

  .footer > h2 {
    font-size: 39px;
  }

  .f-box {
    margin-top: 90px;
  }

  .box {
    width: 302px;
    box-shadow: 0px 21px 25px 0px rgba(73, 97, 114, 0.06);
    padding: 0 56px;
    padding-top: 35px;
    padding-bottom: 72px;
  }

  .finish {
    font-size: 19px;
    font-family: DINPro-Medium, DINPro;
    color: #cecece;
    line-height: 24px;
  }

  .center-t {
    font-size: 27px;
    font-family: Futura-Medium, Futura;
    color: #1e1e22;
    line-height: 35px;
    margin-top: 15px;
  }
  .top-t {
    font-size: 19px;
    font-family: DINPro-Regular, DINPro;
    font-weight: 400;
    color: #858585;
    line-height: 24px;
    margin-top: 30px;
  }
}
</style>
