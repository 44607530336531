<template>
  <div id="app">
    <j-banner></j-banner>
    <j-center></j-center>
    <j-footer></j-footer>
  </div>
</template>

<script>
import JBanner from "./components/j-banner.vue";
import JCenter from "./components/j-center.vue";
import JFooter from "./components/j-footer.vue";

export default {
  name: "App",
  components: { JBanner, JCenter, JFooter },
};
</script>

<style>
#app {
  overflow: hidden;
  width: 100%;
}
</style>
